import BlueCross from '@maven/ui/assets/img/vendor/blue-shield-california.svg';

const cobrandMap = [
	{
		name: 'bsc',
		image: BlueCross,
		description: `Blue Shield of California is an independent member of the Blue Shield Association. Maven is independent of
		Blue Shield of California and is contracted by Blue Shield to provide virtual care services.`,
		displayName: 'Blue Shield of California',
	},
];

export const getCobrandImage = (name: string) => {
	return cobrandMap.find((cobrand) => cobrand.name === name)?.image;
};

export const getCobrandDescription = (name: string) => {
	return cobrandMap.find((cobrand) => cobrand.name === name)?.description;
};

export const getCobrandDisplayName = (name: string) => {
	return cobrandMap.find((cobrand) => cobrand.name === name)?.displayName;
};
