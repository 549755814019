import { TEAM_NAMESPACE, getRoute } from '@maven/datadog';
import podMapper from '@maven/pod-mapper';

export type RoutesType = { [key: string]: Array<RouteOwner> };

const routeOwners = [
	'benefits_experience',
	'care_discovery',
	'content_and_community',
	'enrollments',
	'mpractice_core',
	'outcomes',
	'virtual_care',
] as const;
type RouteOwner = (typeof routeOwners)[number];

export const resolvePodOwnershipFromPath = (routes: RoutesType, path: string) => {
	const route = getRoute(routes, path);
	if (route && routes[route] && routes[route].length > 0) {
		return routes[route][0] || TEAM_NAMESPACE.MAVENCORE;
	}
	return TEAM_NAMESPACE.MAVENCORE;
};

/**
 * Resolve the Pod ownership for a Datadog error from the @maven/pod-mapper configuration
 */
export const resolvePodOwnership = () =>
	resolvePodOwnershipFromPath(podMapper.routes as RoutesType, document.location.pathname);
